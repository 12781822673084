
import {defineComponent, ref} from 'vue'
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {ExaminerSelect} from "@/core/composite/composite";
import ClaimService from "@/core/services/ClaimService";

export default defineComponent({
  name: "AssignExaminer",
  components: {BaseSelect},
  props: {
    claimId: {required: true},
  },
  emits: ['saved'],
  setup() {
    const examiner = ref('');
    const loading = ref(false);
    return {
      examiner,
      loading,
      ...ExaminerSelect()
    }
  },
  methods: {
    setExaminer() {
      this.loading = true;
      ClaimService.setExaminer(this.claimId, this.examiner).then(() => {
        this.$emit('saved');
      }).finally(() => {
        this.loading = false;
      })
    }
  }
})
