
import {defineComponent} from 'vue'
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ReserveCalculator from "@/views/action-form/ReserveCalculator.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AssignExaminer from "@/views/action-form/AssignExaminer.vue";
import ConfirmCoverage from "@/views/action-form/ConfirmCoverage.vue";

export default defineComponent({
  name: "ActionBox",
  components: {ConfirmCoverage, AssignExaminer, ReserveCalculator, BaseModal},
  props: {
    claim: {required:true,type:Object},
  },
  setup() {

  },
  methods: {
    setReserve() {
      const modal = this.$refs.reserveCalculateRef as typeof BaseModal;
      modal.showBaseModal();
    },

    assignEx() {
      const modal = this.$refs.assignRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSavedEx( ) {
      store.dispatch(Actions.LOAD_CLAIM, this.claim.id)
      const modal2 = this.$refs.assignRef as typeof BaseModal;
      modal2.hideBaseModal();
    },
    onSaved() {
      store.dispatch(Actions.LOAD_CLAIM, this.claim.id)
      const modal = this.$refs.reserveCalculateRef as typeof BaseModal;
      modal.hideBaseModal();
    },

    onConfirmCoverage() {
      const modal = this.$refs.confirmCoverageRef as typeof BaseModal;
      modal.showBaseModal();
    },

    onSendConfirmCoverage() {
      store.dispatch(Actions.LOAD_CLAIM, this.claim.id)
      const modal = this.$refs.confirmCoverageRef as typeof BaseModal;
      modal.hideBaseModal();
    },
  }
})
