
import {computed, defineComponent} from "vue";
import store from "@/store";
import Row from "@/components/base/common/Row.vue";
import InquiryStatus from "@/views/case/InquiryStatus.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import {LoadPanel} from "@/core/composite/composite";
import Contacts from "@/views/contact/Contacts.vue";
import Timelines from "@/views/timeline/Timelines.vue";
import DecodeId from "@/components/base/DecodeId.vue";
import ProductLink from "@/views/product/ProductLink.vue";
import CheckList from "@/views/checklist/CheckList.vue";
import ActionBox from "@/views/action-form/ActionBox.vue";

export default defineComponent({
  name: "Overview",
  components: {ActionBox, CheckList, ProductLink, DecodeId, Timelines, Contacts, DateTimeFormat, InquiryStatus, Row},
  setup() {
    const claim = computed(() => store.state.ClaimModule.claim);
    return {
      claim,
      ...LoadPanel(),
    }
  }
})
