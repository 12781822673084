
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormCheckList from "@/views/checklist/FormCheckList.vue";
import CheckListService from "@/core/services/CheckListService";
import {LoadPanel} from "@/core/composite/composite";
import Swal from "sweetalert2";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "CheckList",
  components: {DateTimeFormat, Avatar, FormCheckList, BaseModal, QuickAction},
  props: {
    type: {},
    id: {},
    area: {type: String, default: () => 'CASE'},
    client: {},
  },
  setup(props) {
    const state = ref<any>({
      name: 'Update Actions',
      id: '',
      model: {objectId: '', templateIds: [], type: props.type}
    })

    const filter = ref({type: props.type, id: props.id, area: props.area})
    const loadCheckLists = () => {
      store.dispatch(Actions.LOAD_CHECK_LISTS, filter.value);
    }
    const checkLists = computed(() => store.state.CheckListModule.checkLists);
    loadCheckLists();

    return {
      loadCheckLists,
      checkLists,
      state,
      ...LoadPanel(),
    }
  },
  methods: {
    addCheckList() {
      this.state.model.objectId = this.id;
      this.state.model.type = this.type;
      this.state.model.templateIds = [];
      const baseModal = this.$refs.formCheckListRef as typeof BaseModal
      baseModal.showBaseModal()
    },
    onChange(event, check) {
      event.target.checked = check.status == 'DONE';
    },
    onSaved() {
      this.loadCheckLists()
      const baseModal = this.$refs.formCheckListRef as typeof BaseModal
      baseModal.hideBaseModal()
    },

    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await CheckListService.delete(id).then(() => {
            this.loadCheckLists()
          })
        }
      })
    }
  }
})
