
import {computed, defineComponent, ref} from 'vue'
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {RESERVE_LINE_TYPE} from "@/core/config/Constant";
import FormCurrency from "@/components/base/currency/FormCurrency.vue";
import store from "@/store";
import {Field} from "vee-validate";
import {exchangeRate} from "@/core/helpers/functions";
import ClaimService from "@/core/services/ClaimService";

export default defineComponent({
  name: "ReserveCalculator",
  components: {Field, FormCurrency, BaseSelect, BaseRadio,},
  props: {
    claimId: {required: true},
  },
  emits: ['saved'],
  setup() {
    const loading = ref(false);
    const model = ref({
      country: '',
      type: '',
      guideline: '' ,
          // +
        // 'Base rate of $4,000.00 per day for non-residents for four days then actual amount on record.\n 20% of base rate for physician bills ',
      subType: '',
      otherType: ''
    })
    const reserve = ref<any>({type: 'HOSPITAL', value: 4000, currency: 'CAD', valueCAD: 4000})
    const rates = computed(() => store.state.ExchangeRateModule.rates);
    const calculateTotal = () => {

      const rate = rates.value.find(item => item.base === 'CAD' && item.currency === reserve.value.currency);
      if (rate) {
        reserve.value.valueCAD = exchangeRate(rate.rate, reserve.value.value, true).toFixed(2)
      } else {
        reserve.value.valueCAD = reserve.value.value
      }
    }
    return {
      model,
      reserve,
      RESERVE_LINE_TYPE,
      calculateTotal,
      loading
    }
  },
  methods: {
    onTypeChange() {
      this.setGuideline()
      if (this.model.type === 'OTHER') {
        this.onChangeOtherType()
      }
      if (this.model.type == 'OP') {
        this.onChangeSubType()
      }
    },

    onChangeCountry() {
      this.setGuideline()
      if (this.model.type == 'OP') {
        this.onChangeSubType();
      }
    },
    onChangeSubType() {
      if (!this.model.subType) {
        this.model.subType = 'ROOM_VISIT';
      }
      if (this.model.subType === 'ROOM_VISIT') {
        if (this.model.country === 'CA' || this.model.country === 'OTHER') {
          this.reserve = {type: 'HOSPITAL', value: 2000, currency: 'CAD', valueCAD: 2000};
        }
        if (this.model.country === 'US') {
          this.reserve = {type: 'HOSPITAL', value: 4000, currency: 'CAD', valueCAD: 4000};
        }
      }

      if (this.model.subType === 'PHYSICIAN_VISIT') {
        if (this.model.country === 'CA' || this.model.country === 'OTHER') {
          this.reserve = {type: 'PHYSICIAN', value: 150, currency: 'CAD', valueCAD: 150};
        }
        if (this.model.country === 'US') {
          this.reserve = {type: 'PHYSICIAN', value: 500, currency: 'CAD', valueCAD: 500};
        }
      }

      if (this.model.subType === 'AMBULANCE_CHARGE') {
        if (this.model.country === 'CA' || this.model.country === 'OTHER') {
          this.reserve = {type: 'OTHER_EXPENSES', value: 300, currency: 'CAD', valueCAD: 300};
        }
        if (this.model.country === 'US') {
          this.reserve = {type: 'OTHER_EXPENSES', value: 400, currency: 'CAD', valueCAD: 400};
        }
      }
      if (this.model.subType === 'MEDICAL_RECORDS') {
        if (this.model.country === 'CA' || this.model.country === 'OTHER') {
          this.reserve = {type: 'OTHER_EXPENSES', value: 150, currency: 'CAD', valueCAD: 150};
        }
        if (this.model.country === 'US') {
          this.reserve = {type: 'OTHER_EXPENSES', value: 150, currency: 'CAD', valueCAD: 150};
        }
      }
    },

    onChangeOtherType() {
      if (!this.model.otherType) {
        this.model.otherType = 'AIR_AMB';
      }
      if (this.model.otherType === 'AIR_AMB') {
        this.reserve = {type: 'AIR_AMBULANCE', value: 20000, currency: 'CAD', valueCAD: 20000};
      }
      if (this.model.otherType === 'REPATRIATION') {
        this.reserve = {type: 'OTHER_EXPENSES', value: 5000, currency: 'CAD', valueCAD: 5000};
      }
      if (this.model.otherType === 'MAJOR') {
        this.reserve = {type: 'OTHER_EXPENSES', value: 3000, currency: 'CAD', valueCAD: 3000};
      }

    },

    setGuideline() {
      if (this.model.country === 'US') {
        if (this.model.type === 'IP') {
          this.model.guideline = `Base rate of $7,000.00 per day for the first four days then actual amount on records plus 20%.\n20% of base rate for physician bills
          `;
          this.reserve = {type: 'HOSPITAL', value: 7000, currency: 'CAD', valueCAD: 7000};

        }
        if (this.model.type === 'OP') {
          this.model.guideline =
            'Base rate of $4,000.00 for an emergency room visit\n' +
            'Base rate of $500.00 for a physician visit \n' +
            'Base rate of $400.00 for an ambulance charge\n' +
            'Base rate of $150.00 for medical records\n'
        }
        if (this.model.type === 'OTHER') {
          this.model.guideline = 'Air Ambulance \n' +
            '  -Florida to Ontario $20,000 to $25,000\n' +
            '  -Florida to West Coast $35,000 to $55,000\n' +
            '  -California to British Colombia $20,000 to $25,000\n' +
            '  -California to Ontario 35,000 to $55,000\n' +
            '  -Mexico to Canada $50,000 to $70,000\n\n' +
            'Repatriation of remains $5,000\n' +
            'Major event returns $3,000\n' +
            'Other reserves for benefits as notified and policy allows.\n';
        }
      }
      if (this.model.country === 'CA') {
        if (this.model.type === 'IP') {
          this.model.guideline = `Base rate of $4,000.00 per day for non-residents for four days then actual amount on record.\n20% of base rate for physician bills`;
          this.reserve = {type: 'HOSPITAL', value: 4000, currency: 'CAD', valueCAD: 4000};
        }
        if (this.model.type === 'OP') {
          this.model.guideline = 'Base rate of $2,000.00 for an emergency room visit\n' +
            'Base rate of $150.00 for a physician visit \n' +
            'Base rate of $300.00 for an ambulance charge\n' +
            'Base rate of $150.00 for medical records\n'

        }
        if (this.model.type === 'OTHER') {
          this.model.guideline = 'Air Ambulance \n' +
            '  -Florida to Ontario $20,000 to $25,000\n' +
            '  -Florida to West Coast $35,000 to $55,000\n' +
            '  -California to British Colombia $20,000 to $25,000\n' +
            '  -California to Ontario 35,000 to $55,000\n' +
            '  -Mexico to Canada $50,000 to $70,000\n\n' +
            'Repatriation of remains $5,000\n' +
            'Major event returns $3,000\n' +
            'Other reserves for benefits as notified and policy allows.\n';
        }
      }
      if (this.model.country === 'OTHER') {
        if (this.model.type === 'IP') {
          this.model.guideline = `Base rate of $4,000.00 per day for non-residents for four days then actual amount on record.\n20% of base rate for physician bills`;
          this.reserve = {type: 'HOSPITAL', value: 16000, currency: 'CAD', valueCAD: 16000};
        }
        if (this.model.type === 'OP') {
          this.model.guideline = 'Base rate of $2,000.00 for an emergency room visit\n' +
            'Base rate of $150.00 for a physician visit \n' +
            'Base rate of $300.00 for an ambulance charge\n' +
            'Base rate of $150.00 for medical records\n'

        }
        if (this.model.type === 'OTHER') {
          this.model.guideline = 'Air Ambulance \n' +
            '  -Florida to Ontario $20,000 to $25,000\n' +
            '  -Florida to West Coast $35,000 to $55,000\n' +
            '  -California to British Colombia $20,000 to $25,000\n' +
            '  -California to Ontario 35,000 to $55,000\n' +
            '  -Mexico to Canada $50,000 to $70,000\n\n' +
            'Repatriation of remains $5,000\n' +
            'Major event returns $3,000\n' +
            'Other reserves for benefits as notified and policy allows.\n';
        }
      }
    },

    createReserve() {
      this.loading = true
      ClaimService.setReserve(this.claimId, this.reserve).then(() => {
        this.$emit('saved')
      }).finally(() => {
        this.loading = false;
      })
    }
  }

})
